.navigationItems {
  width: 46.34vw;
  max-width: 768px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.navigationItems ul {
  display: flex;
  width: 15.9vw;
  max-width: 500px;
  min-width: 200px;
  height: 100%;
  list-style: none;
  justify-content: space-between;
  align-items: center;
}
.navigationItems li {
  color: #ffffff;
  cursor: pointer;
  font-size: 18px;
  font-family: med;
  transition: all 0.2s ease-in-out;
}
.navigationItems li:hover {
  color: #e0e0ff !important;
}

.navButton,
.navButtonRes {
  outline: none;
  border: transparent;
  width: 104px;
  height: 41px;
  background: #ff2c53;
  border-radius: 24px;
  border: none;
  color: #ffffff;
  font-size: 16px;
  font-family: semi;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  padding-bottom: 0.2vh;
}
.loggedIn {
  width: 9.15vw;
  max-width: 145px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bell {
  position: relative;
  cursor: pointer;
}
.bellNo {
  position: absolute;
  top: -7px;
  right: -7px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #ff2c53;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 13px;
  font-family: gilSemi;
}
.name {
  font-size: 13px;
  font-family: med;
  color: #ff2c53;
}
.logout {
  font-size: 13px;
  font-family: med;
  color: #ffffff;
  cursor: pointer;
}
@media (min-height: 1000px) {
  .navButton,
  .navButtonRes {
    max-height: 80px;
  }
}

.navButtonRes,
.logoutContainerRes {
  display: none;
}
.navigationItems button:hover {
  color: #e0e0ff;
}
a {
  text-decoration: none;
}
@media (max-width: 950px) {
  .navigationItems ul {
    width: 280px;
    flex-direction: column;
    text-align: center;
    margin-top: 20px;
    padding-right: 0;
  }
  .navButtonRes {
    width: 100px;
    height: 40px;
    display: inline;
  }

  .navigationItems li {
    width: 100%;
    margin-bottom: 50px;
    font-size: 16px;
    cursor: pointer;
    color: #ffffff;
  }
  .navigationItems ul li {
    color: #ffffff;
  }
  .bell,
  .avatar,
  .logoutContainer {
    display: none;
  }
}

.avatar{
  width: 3.3rem;
  height: 3.3rem;
  margin: 0.5rem;
  border-radius: 100%;
}
@media (max-width: 950px) {
  .navigationItems {
    justify-content: center;
  }
  .navButton {
    display: none;
  }
  .navButtonRes {
    font-size: 14px;
  }
}
