.ispContainer {
  width: 100%;
  height: 572px;
  min-height: 76vh;
  background-color: #2a2b36;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 8vh;
}
.content {
  width: 1110px;
  min-width: 540px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.firstRow,
.secondRow {
  width: 540px;
  height: 502px;
}

@media (max-width: 1100px) {
  .ispContainer {
    height: auto;
    padding-bottom: 50px;
  }
  .content {
    justify-content: center;
  }
}
