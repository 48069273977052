.generateCard{
    width: 540px;
    height: 96px;
    background:linear-gradient(to right bottom,#2A2B36,#2A2B36,#272833);
    box-shadow:1px 1px 5px #090D14;
margin-top: 30px;
display: flex;
align-items: center;
flex-direction: row-reverse;
padding-right: 24px;
}
.btnsContainer{
    width: 287px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.generate{
    width: 190px;
    height: 48px;
    border-radius:24px ;
    background-color: transparent;
    border: solid 2px #FF2C53;
    outline: none;
    cursor: pointer;
    font-size: 16px;
    font-family: semi;
    color: #FF2C53;
}
.buy{
    outline: none;
    cursor: pointer;
width: 81px;
height: 48px;
background-color: #FF2C53;
color: #ffffff;
font-size: 16px;
font-family: med;
border: none;
border-radius:24px ;
}
.btnsContainer button{
    transition:all 0.2s ease-in-out;
}
.btnsContainer button:hover{
    transform: scale(1.05);
}