.layout {
  position: relative;
  background-color: #2A2B36;
  padding-top: 16.74vh;
}
@media (max-width: 600px) {
  .layout {
    height: 100vh;
    overflow-y: hidden;
  }
}
