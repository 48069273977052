.proxiesContainer {
  width: 540px;
  height: 376px;
  background: linear-gradient(to right bottom, #2a2b36, #2a2b36, #272833);
  box-shadow: 1px 1px 5px #090d14;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-left: 24px;
  padding-right: 24px;
}
.header {
  width: 492px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 20px;
}
.title {
  font-size: 18px;
  font-family: semi;
  color: #ffffff;
}
.icons {
  width: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.icons svg {
  cursor: pointer;
}
.proxiesSelect {
  position: relative;
  margin-bottom: 10px;
}
.arrow {
  position: absolute;
  right: 12px;
  top: 12px;
  pointer-events: none;
}
.proxies,
.quantity,
.type {
  width: 160px;
  height: 48px;
  border-radius: 24px;
  background-color: rgba(224, 224, 255, 0.03);
  border: none;
  outline: none;
  padding-left: 14px;
  color: #ffffff;
  font-size: 14px;
  font-family: semi;
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
}
.quantity::-webkit-outer-spin-button,
.quantity::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.type option,
.proxies option {
  background-color: #2a2b36;
  border: none;
}
.quantity::placeholder,
.proxies,
.type {
  color: rgba(224, 224, 255, 0.6);
}

.proxiesList {
  width: 278px;
  height: 268px;
  overflow: auto;
}
.proxiesList li {
  font-size: 13px;
  font-family: semi;
  color: rgba(224, 224, 255, 0.6);
  padding-bottom: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.proxiesList::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.proxiesList::-webkit-scrollbar-track {
  background: #174449;
  border-radius: 8px;
}

.proxiesList::-webkit-scrollbar-thumb {
  background: #ff2c53;
  border-radius: 8px;
}

.proxiesList::-webkit-scrollbar-thumb:hover {
  background: rgb(121, 89, 1);
}

.copiedAlert {
  height: 5.5vh;
  background-color: #070707;
  color: #ffffff;
  font-size: 1.3vw;
  position: fixed;
  display: flex;
  justify-content: center;
  padding: 1.5vh 1vw 0;
  border-radius: 8px;
  top: 90%;
  left: 50%;
  z-index: 2;
}
