* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@font-face {
  font-family: bol;
  src: url(./fonts/Montserrat-Bold.ttf);
}
@font-face {
  font-family: med;
  src: url(./fonts/Montserrat-Medium.ttf);
}
@font-face {
  font-family: semi;
  src: url(./fonts/Montserrat-SemiBold.ttf);
}
@font-face {
  font-family: averta;
  src: url(./fonts/AvertaStd-Bold.ttf);
}
@font-face {
  font-family: gilSemi;
  src: url(./fonts/Gilroy-SemiBold.ttf);
}
@font-face {
  font-family: gilExtra;
  src: url(./fonts/Gilroy-ExtraBold.ttf);
}
@font-face {
  font-family: extraLoginFont;
  src: url(./fonts/Montserrat-ExtraBold.ttf);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@media (max-width: 560px) {
  body {
    overflow-x: hidden;
  }
}
body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: #174449;
}

body::-webkit-scrollbar-thumb {
  background: #ff2c53;
  border-radius: 8px;
}

body::-webkit-scrollbar-thumb:hover {
  background: rgb(121, 89, 1);
}
.message {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  text-align: center;
  font-family: med;
}
.message span {
  color: #ffffff;
  font-size: 24px;
  width: 80%;
}
@media (max-width: 700px) {
  .message span {
    font-size: 18px;
  }
}
.message button {
  outline: none;
  border: transparent;
  width: 117px;
  height: 43px;
  background: #ff2c53;
  border-radius: 24px;
  color: #ffffff;
  font-size: 1.12;
  font-family: semi;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin-top: 3vh;
}
.message button:hover {
  transform: scale(1.1);
}
