.doughnutCard{
    width: 540px;
    height: 164px;
    background:linear-gradient(to right bottom,#2A2B36,#2A2B36,#272833);
    box-shadow:1px 1px 5px #090D14;
    display: flex;
    align-items: center;
    justify-content: space-between;    
    margin-top: 27px;
    padding-left:24px ;
    padding-right:24px ;
}
.resi,.date{
    font-size: 18px;
    color: #ffffff;
    font-family: semi;
}
.souse{
    font-size: 13px;
    color: #E0E0FF;
    font-family: semi;
    padding-top: 3px;
}
.date{
    padding-top:33px ;
}
@media (max-width:1100px) {
.doughnutCard{
    margin-bottom: 30px;
}
}