.loginContainer {
  width: 300px;
  height: 225px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.close {
  position: absolute;
  top: 28.5px;
  right: 28px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  z-index: 1;
}
.close:hover path {
  fill: #ffffff;
}
.content {
  width: 245px;
  /* height: 19.68vh; */
}
.title {
  width: 100%;
  margin-bottom: 29px;
  margin-top: 23px;
}
.login {
  color: #ffffff;
  font-size: 35px;
  font-family: extraLoginFont;
}
.getStarted {
  font-size: 14px;
  color: #e0e0ff;
  font-family: semi;
  opacity: 0.6;
}
.discordBtn,
.twitterBtn {
  width: 100%;
  height: 48px;
  border-radius: 24px;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 16px;
  font-family: med;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.discordBtn {
  background-color: #7289da;
  margin-bottom: 16px;
}
.twitterBtn {
  background-color: #4579f5;
}
.discordBtn:hover,
.twitterBtn:hover {
  transform: scale(1.1);
}
.discord {
  margin-right: 12px;
}
@media (max-width: 700px) {
  .loginContainer {
    width: 260px;
    height: 200px;
  }
  .content {
    width: 200px;
  }
  .login {
    font-size: 24px;
  }
  .getStarted {
    font-size: 12px;
  }
  .signIn {
    font-size: 12px;
  }
  .social {
    width: 110px;
  }
  .item {
    font-size: 10px;
  }
  .or {
    font-size: 8px;
  }
  .close {
    top: 2vh;
  }
  .discordBtn,
  .twitterBtn {
    font-size: 14px;
  }
}
