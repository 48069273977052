.header {
  width: 82vw;
  max-width: 1110px;
  min-width: 540px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
}
.title {
  font-size: 30px;
  color: #ffffff;
  font-family: bol;
}
.proxySelect {
  width: 286px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.proxyPlan {
  font-size: 16px;
  color: #ffffff;
  font-family: semi;
}
.proxiesContainer {
  position: relative;
}
.arrow {
  position: absolute;
  right: 12px;
  top: 12px;
  pointer-events: none;
}
.proxies {
  width: 160px;
  height: 48px;
  border-radius: 24px;
  background: #2f2f3a;
  border: none;
  outline: none;
  padding-left: 24px;
  color: #ffffff;
  font-size: 16px;
  font-family: semi;
  appearance: none;
}
@media (max-width: 750px) {
  .title {
    font-size: 24px;
  }
  .proxySelect {
    width: 240px;
  }
  .proxyPlan {
    font-size: 12px;
  }
  .proxies {
    width: 120px;
    height: 38px;
    font-size: 12px;
  }
  .arrow {
    right: 8px;
    top: 8px;
  }
}
