.doughnutChart {
    display: flex;
    align-items: center;
    position: relative;
    padding-top: 10px;
  }
  .doughnutChart canvas {
    width: 130px;
    height: 130px;
  }
  .title {
    position: absolute;
    text-align: center;
    left: 1.8em;
  }
  .number {
    font-size: 32px;
    font-family: averta;
    color: #ffffff;
  }
  .gb{
      color: #E0E0FF;
    font-size: 10px;
    font-family: med;  }
  
    .notAvailable {
      position: absolute;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      border-radius: 8px;
      background-color: rgba(0, 0, 0, 0.3);
      -webkit-backdrop-filter: blur(3px);
      backdrop-filter: blur(3px);
    }
    .notAvailable h4 {
      font-size: 24px;
      color: #ffffff;
    }