.proxiesContainer {
  width: 540px;
  height: 376px;
  background: linear-gradient(to right bottom, #2a2b36, #2a2b36, #272833);
  box-shadow: 1px 1px 5px #090d14;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-left: 24px;
  padding-right: 24px;
}
.header {
  width: 492px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 20px;
}
.title {
  font-size: 18px;
  font-family: semi;
  color: #ffffff;
}
.icons {
  width: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.icons svg {
  cursor: pointer;
}
.proxiesListContainer {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  position: relative;
}
.proxiesList {
  width: 100%;
  height: 268px;
  overflow: auto;
}
.proxiesList li {
  font-size: 13px;
  font-family: semi;
  color: rgba(224, 224, 255, 0.6);
  padding-bottom: 12px;
}
.proxiesList::-webkit-scrollbar {
  width: 8px;
}

.proxiesList::-webkit-scrollbar-track {
  background: #174449;
  border-radius: 8px;
}

.proxiesList::-webkit-scrollbar-thumb {
  background: #ff2c53;
  border-radius: 8px;
}

.proxiesList::-webkit-scrollbar-thumb:hover {
  background: rgb(121, 89, 1);
}

.copiedAlert {
  height: 5.5vh;
  background-color: #070707;
  color: #ffffff;
  font-size: 1.3vw;
  position: fixed;
  display: flex;
  justify-content: center;
  padding: 1.5vh 1vw 0;
  border-radius: 8px;
  top: 90%;
  left: 50%;
  z-index: 2;
}
.notAvailable {
  position: absolute;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
}
.notAvailable h4 {
  font-size: 24px;
  color: #ffffff;
}