.notAllowed {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  z-index: 100000000000000000000;
  color: #ffffff;
  font-size: 20px;
  font-family: gilExtra;
}
@media (max-width: 600px) {
  .notAllowed {
    display: flex;
  }
}
