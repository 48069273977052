.orderHistory {
  width: 540px;
  height: 311px;
  background: linear-gradient(to right bottom, #2a2b36, #2a2b36, #272833);
  box-shadow: 1px 1px 5px #090d14;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
}
.header {
  width: 492px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
}
.usage {
  font-size: 18px;
  color: #ff2c53;
  font-family: semi;
}
.buy {
  outline: none;
  cursor: pointer;
  width: 131px;
  height: 48px;
  background-color: #ff2c53;
  color: #ffffff;
  font-size: 16px;
  font-family: med;
  border: none;
  border-radius: 24px;
  transition: all 0.2s ease-in-out;
}
.buy:hover {
  transform: scale(1.05);
}
.cancel {
  outline: none;
  cursor: pointer;
  width: 80px;
  height: 30px;
  background-color: #ff2c53;
  color: #ffffff;
  font-size: 16px;
  font-family: med;
  border: none;
  border-radius: 24px;
  transition: all 0.2s ease-in-out;
  margin: 4px;
}
.cancel:hover {
  transform: scale(1.05);
  background-color: #2a2b36;
}
.ordersTable {
  width: 492px;
  height: 220px;
  border-top: 1px dashed #ff2c53;
  margin-top: 15px;
  overflow: auto;
}
.ordersTable::-webkit-scrollbar {
  width: 8px;
}

.ordersTable::-webkit-scrollbar-track {
  background: #174449;
  border-radius: 8px;
}

.ordersTable::-webkit-scrollbar-thumb {
  background: #ff2c53;
  border-radius: 8px;
}

.ordersTable::-webkit-scrollbar-thumb:hover {
  background: rgb(121, 89, 1);
}

.orders {
  width: 100%;
  margin-top: 10px;
}
.orders th {
  font-size: 12px;
  color: #ffffff;
  height: 30px;
  background-color: rgba(224, 224, 255, 0.03);
}

tr:hover {
  background-color: #ff2c53;
  cursor: pointer;
}

.activeplan {
  background-color: #ff2c53;
}
.activeplanbtn {
  outline: none;
  cursor: pointer;
  width: 80px;
  height: 30px;
  background-color: #2a2b36;
  color: #ffffff;
  font-size: 16px;
  font-family: med;
  border: none;
  border-radius: 24px;
  transition: all 0.2s ease-in-out;
  margin: 4px;
}
.activeplanbtn:hover {
  transform: scale(1.05);
  background-color: #2a2b36;
}
.notAvailable {
  position: absolute;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
}
.notAvailable h4 {
  font-size: 24px;
  color: #ffffff;
}
