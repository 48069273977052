.backdrop,
.modalBackdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  background-color: #090d14;
  opacity: 0.8;
}
@media (min-width: 950px) {
  .backdrop {
    display: none;
  }
}
