.lineChartContainer {
  width: 540px;
  height: 311px;
  background: linear-gradient(to right bottom, #2a2b36, #2a2b36, #272833);
  box-shadow: 1px 1px 5px #090d14;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
}
.header {
  width: 492px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
}
.usage {
  font-size: 18px;
  color: #ffffff;
  font-family: semi;
}
.proxiesContainer {
  position: relative;
}
.arrow {
  position: absolute;
  right: 6px;
  top: 6px;
  pointer-events: none;
}
.proxies {
  width: 118px;
  height: 32px;
  border-radius: 24px;
  background-color: rgba(224, 224, 255, 0.03);
  border: none;
  outline: none;
  padding-left: 24px;
  color: #ffffff;
  font-size: 16px;
  font-family: semi;
  appearance: none;
}

.notAvailable {
    position: absolute;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.3);
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
  }
  .notAvailable h4 {
    font-size: 24px;
    color: #ffffff;
  }