.navigation {
  width: 100%;
  box-sizing: border-box;
  backdrop-filter: blur(108.731px);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  height: 66.5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo {
  width: 4.02vw;
  height: 4vh;
  cursor: pointer;
}
.content {
  height: 100%;
  width: 82vw;
  max-width: 1100px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 950px) {
  .navItems {
    display: none;
  }
  .logo {
    width: 35px;
    height: 35px;
  }
}
