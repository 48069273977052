.termsContainer {
  width: 100%;
  height: 876px;
  background-color: #2a2b36;
  display: flex;
  align-items: center;
}
.content {
  width: 77vw;
  max-width: 1100px;
  margin: auto;
}
.header {
  text-align: center;
}
.title {
  font-size: 36px;
  color: #ffffff;
  font-family: extra;
}
.desc {
  font-size: 16px;
  color: #e0e0ff;
  opacity: 0.6;
  font-family: med;
  padding: 28px 0;
  width: 820px;
  margin: auto;
}
.termsTit {
  font-size: 24px;
  color: #ffffff;
  padding: 25px 0 30px;
  font-family: bol;
}

.termsSubTit {
  font-size: 18px;
  color: #ffffff;
  padding-bottom: 30px;
  font-family: semi;
}
.termsDesc {
  font-size: 16px;
  color: #e0e0ff;
  font-family: med;
  padding-bottom: 30px;
}
.pop {
  animation: popup 0.3s ease-in-out 0.4s forwards;
}
@keyframes popup {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.01);
  }
  100% {
    transform: scale(1);
  }
}
@media (max-width: 700px) {
  .title {
    font-size: 26px;
  }
  .content {
    width: 90%;
  }
  .desc {
    font-size: 11px;
    padding: 15px 0 25px;
    width: 95%;
  }
  .termsTit {
    font-size: 18px;
  }
  .termsSubTit {
    font-size: 12px;
  }
  .termsDesc {
    font-size: 10px;
  }
}
